import photo1 from "../../../images/header_background.jpg";
import photo2 from "../../../images/logoDoty.jpg";

export const offer = [
  {
    imgSrc: photo1,
    title: `PROJEKTY WNĘTRZ`,
  },
  {
    imgSrc: photo2,
    title: `PROJEKTY GRAFICZNE`,
  },
];
