import { StyledQuote, Quotation } from "./styled";

const Quote = () => (
  <StyledQuote>
    <Quotation>
      Granice nie <br /> istnieją, <br /> ustalamy je sami...
    </Quotation>
  </StyledQuote>
);

export default Quote;
